import { navigate } from '@reach/router';
import bg_select from 'assets/svg/bg-select.svg';
import IconDollar from 'assets/svg/icons/dollar.inline.svg';
import IconFile from 'assets/svg/icons/file.inline.svg';
import SelectBg from 'assets/svg/select-col-light.inline.svg';
import Button from 'components/Molecules/Button';
import { routes } from 'constants/routes';
import HeaderContext from 'providers/HeaderProvider';
import ThemeContext from 'providers/ThemeProvider';
import React, { useContext, useEffect } from 'react';
import { css } from 'twin.macro';

const bgSelect = css`
	max-height: 800px;
	height: 65vw;
	position: relative;
	background-image: url(${bg_select});
	background-position: center;
	background-repeat: no-repeat;
	background-size: contain;
`;

export default function select() {
	const { setHeader } = useContext(HeaderContext);
	const { setPrimaryColor } = useContext(ThemeContext);

	useEffect(() => {
		setHeader({ title: 'Produkt Selekt', isSelect: true });
		setPrimaryColor('#3B81B9');
	}, []);

	return (
		<div>
			<div css={bgSelect} className='flex flex-col items-center justify-center w-full mb-15'>
				<SelectBg className='w-120 h-120' />
				<p className='font-semibold'>Faktoring zagraniczny</p>
			</div>

			<div className='flex flex-col items-center justify-center px-15 mb-10'>
				<div className='w-full'><p className='text-16 w-17/20 mt-05 mb-15 leading-15'>Dla kogo faktoring zagraniczny?</p></div>
				<p>Odbiorcy Twoich usług lub nabywcy produktów to firmy z siedzibą zarejestrowaną poza granicami Polski? SELEKT to rozwiązanie właśnie dla Ciebie. Dzięki Usłudze Selekt dostaniesz środki od Smart Faktor od razu po zgłoszeniu wierzytelności do wykupu, Faktor przelewa pieniądze w ustalonej walucie na Twoje konto nawet tego samego dnia i cierpliwie czeka na zapłatę przedmiotowej faktury w dogodnej walucie przez Twojego klienta w umówionym terminie.</p>

				<Button primary onClick={() => navigate(routes.SELECT_FORM)} tw='bg-primary mt-10 mb-20'>
					<IconDollar/><p>Sprawdź ofertę</p>
				</Button>

				<img src='../../../files/pic1.svg' className="w-110 h-110" />
				<div className='w-full'><p className='text-16 w-17/20 mt-05 mb-15 leading-15'>Jak to działa?</p></div>
				<p>Na Twoją firmę przyznamy limit , nawet 500.000,00 zł – w ramach tego przyznamy też limity na Twoich kontrahentów. Przyjmiemy wszystkie faktury do górnej wysokości tych limitów. Limit jest odnawialny - co oznacza, iż jest do ponownego wykorzystania w dniu spłaty poprzednich wierzytelności. Wykorzystujesz ile chcesz i kiedy chcesz, Twój limit możesz traktować jak typowy stand-by lub overdraft.</p>

				<Button primary onClick={() => navigate(routes.WELCOME_SELECT_ADVANCED_FORM)} tw='bg-primary mt-10 mb-20'>
					<IconFile/><p>Złóż wniosek</p>
				</Button>

				<img src='../../../files/pic2.svg' className="w-110 h-110" />
				<div className='w-full'><p className='text-16 w-17/20 mt-05 mb-15 leading-15'>Jakie waluty obsługujemy?</p></div>
				<p className='mb-30'>Przyjmujemy faktury lub inne dokumenty we wszystkich najpopularniejszych walutach a fizyczne płatności umożliwiamy w EUR, USD, CNY oraz SEK. Niemniej jednak klient nie musi posiadać rachunku rozliczeniowego w walucie z faktury –zapłacimy za nią w PLN przeliczając należność po korzystnym kursie.</p>

				<img src='../../../files/pic3.svg' className="w-110 h-110" />
				<div className='w-full'><p className='text-16 w-17/20 mt-05 mb-15 leading-15'>Jakie są wysokości zaliczek płaconych za nabywane wierzytelności?</p></div>
				<p className='mb-30'>Jako Faktor płacimy zaliczkę za nabywane wierzytelności – wynosi ona od 35% do 90 % wartości faktury. Wysokość zaliczki wpływa na cenę całej usługi – im niższa zaliczka, tym niższa cena. Pozostałą część faktury pomniejszoną o koszty naszych usług wypłacamy niezwłocznie po otrzymaniu płatności od Twojego kontrahenta.</p>

				<img src='../../../files/pic4.svg' className="w-110 h-110" />
				<div className='w-full'><p className='text-16 w-17/20 mt-05 mb-15 leading-15'>Jakie terminy płatności akceptujemy?</p></div>
				<p>Dobrze wiemy, że płynność to kluczowa sprawa w prowadzeniu biznesu. Wiemy też, że długi termin płatności to często ważny argument w negocjacjach handlowych a czasami wręcz konieczność. Wychodząc temu naprzeciw przyjmujemy do wykupu faktury z terminem płatności nawet do 180 dni.</p>

				<Button primary onClick={() => navigate(routes.SELECT_FORM)} tw='bg-primary mt-30 mb-15'>
					<IconDollar/><p>Sprawdź ofertę</p>
				</Button>
				<Button primary onClick={() => navigate(routes.WELCOME_SELECT_ADVANCED_FORM)} tw='bg-primary'>
					<IconFile/><p>Złóż wniosek</p>
				</Button>
			</div>
		</div>
	);
}
